const subTitle = "Full Stack (MERN) Developer";
const title = (
  <h2 className="title">
    <span className="d-lg-block">Suraj Limbikar</span>
    {/* <span className="d-lg-block">Limbikar</span> */}
  </h2>
);
const desc =
  "Hello there! I'm a Web developer, and I'm very passionate and dedicated to my work. With 3 years experience as a professional web developer, I have acquired the skills and knowledge necessary to make project a success. I enjoy every step of the development process, from discussion and collaboration.";

const catagoryList = [
  {
    name: "Figma",
    link: "#",
  },
  {
    name: "Adobe XD",
    link: "#",
  },
  {
    name: "illustration",
    link: "#",
  },
  {
    name: "Photoshop",
    link: "#",
  },
];

const shapeList = [
  {
    name: "16M Students Happy",
    link: "#",
    className: "ccl-shape shape-1",
  },
  {
    name: "130K+ Total Courses",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "89% Successful Students",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "23M+ Learners",
    link: "#",
    className: "ccl-shape shape-4",
  },
  {
    name: "36+ Languages",
    link: "#",
    className: "ccl-shape shape-5",
  },
];

const Banner = () => {
  return (
    <section className="banner-section">
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div className="col-xxl-7 col-xl-6 col-lg-10 order2">
              <div className="banner-content">
                {title}
                <h6 className="subtitle  fw-medium">{subTitle}</h6>
                <p className="desc">{desc}</p>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-6 order1">
              <div className="banner-thumb fadeInUp text-align-center">
                <img src="assets/images/suraj-profile-photo.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="all-shapes"></div> */}
      {/* <div className="cbs-content-list d-none">
        <ul className="lab-ul">
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div> */}
    </section>
  );
};

export default Banner;
